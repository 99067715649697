<style scoped>
.process-complete{
    color: #2A7D0D !important;
}
.process-overdue{
    color: #A02F42 !important;
}
.process-warn{
    color: #C4C935 !important;
}
.process-none{
    color: #1a1a1a;
}

.process-title{
    padding: 2px 0;
    border-right: 1px solid #808695;
}
.process-content{
    padding: 5px 0;
    border-right: 1px solid #808695;
}
</style>

<template>
    <div>
        <h2 class="p-t-5 p-l-5">品牌投放履约进程图</h2>
        <Divider style="margin: 5px 0;"/>

        <Row class="p-b-5">
            <i-col offset="8" span="6" class="p-t-10">
                <!-- <span class="text-orange">*数值为（逾期天数/最后完成日期）</span> -->
            </i-col>
            <i-col span="10">
                <ul style="list-style-type:none;">
                    <li class="tipBox"><a href="javascrip:void(0);" style="background-color:#2A7D0D"></a>提前完成</li>
                    <li class="tipBox"><a href="javascrip:void(0);" style="background-color:#A02F42"></a>逾期完成</li>
                    <li class="tipBox"><a href="javascrip:void(0);" style="background-color:#C4C935"></a>预警</li>
                </ul>
            </i-col>
        </Row>

        <Row class="table-title text-center text-14">
            <i-col span="4" class="process-title">客户</i-col>
            <i-col span="4" class="process-title">品牌</i-col>
            <i-col span="4" class="process-title">发布档期</i-col>
            <i-col span="2" class="process-title" v-for="item in taskprogresstypes" :key="item.value">{{item.name}}</i-col>
            <!-- <i-col span="2" class="process-title">内容审批</i-col>
            <i-col span="2" class="process-title">上刊设置</i-col>
            <i-col span="2" class="process-title">供应商收单</i-col>
            <i-col span="2" class="process-title">工人收单</i-col>
            <i-col span="2" class="process-title">物流收单</i-col>
            <i-col span="2" class="process-title">上刊完成</i-col> -->
        </Row>

        <Row v-for="(item,index) in brandData" :key="index" class="text-center">
            <i-col span="4" class="process-content" :class=" item.status===1?'process-warn':item.status===2?'process-overdue':item.status===3?'process-complete':''">
                <span v-if="item.advertiserName.length<=10"> {{item.advertiserName}}</span>
                <Tooltip v-else :content="item.advertiserName">
                    {{item.advertiserName.substr(0,10)}}..
                </Tooltip>
            </i-col>
            <i-col span="4" class="process-content">
                <span v-if="item.brandName.length<=10"> {{item.brandName||'-'}}</span>
                <Tooltip v-else :content="item.brandName">
                    {{item.brandName.substr(0,10)}}..
                </Tooltip>
            </i-col>
            <i-col span="4" class="process-content">{{item.startDate}} 至 {{item.endDate}}</i-col>

            <i-col span="2" v-for="type in taskprogresstypes" :key="type.value" class="process-content">
                <div :class="checkProcessStatus(item,type.value)">
                  <span >{{computedOverdue(item,type.value)}}</span>
                </div>
                <!-- <span v-if="item.step === num-1" :class="item.warning?'text-orange':''">{{item.day}}</span>
                <template v-else>
                    <div v-if="item.error && item.error === num" class="process-error">{{item.errorDay}}</div>
                    <div v-else :class="item.step>num-1? 'process-complete':'process-none'">-</div>
                </template> -->
            </i-col>
        </Row>
        <Divider style="margin: 0;"/>

        <div class="paging_style">
            <Page size="small" show-total show-elevator :total="totalRow" :page-size="query.pageSize" @on-change="handlePageChange"></Page>
        </div>
    </div>
</template>

<script>
import { getTaskProgressPage } from '@/api/msp/taskwork'
import { getTaskProgressType } from '@/api/msp/status'

export default {
  data () {
    return {
      query: {
        pageNumber: 1,
        pageSize: 20
      },
      brandData: [],
      totalRow: 0,
      taskprogresstypes: []
    }
  },
  created () {
    this.initprogresstypeData()
    this.initPageData()
  },
  methods: {
    // 获取履约进程类型
    initprogresstypeData () {
      const that = this
      getTaskProgressType({}).then(res => {
        that.taskprogresstypes = res.sort((a, b) => a.value - b.value)
      })
    },

    initPageData () {
      getTaskProgressPage(this.query).then(res => {
        this.brandData = res.list
        this.totalRow = res.totalRow
      })
    },
    handlePageChange (page) {
      this.query.pageNumber = page
      this.initPageData()
    },
    checkProcessStatus (item, status) {
      const currentProcess = item.taskProgressTypeList.find(x => x.taskProgressTypeId === status)
      if (currentProcess) { // 当前内容项
        if (currentProcess.isFinish) { // 判断时候逾期
          return currentProcess.finishedDays < 0 ? 'process-overdue' : 'process-complete'
        } else { // 未完成
          return currentProcess.surplusDays <= currentProcess.nodeDays ? 'process-warn' : ''
        }
      } else {
        return 'process-none'
      }
    },
    computedOverdue (item, status) {
      const currentProcess = item.taskProgressTypeList.find(x => x.taskProgressTypeId === status)

      if (currentProcess.isFinish) {
        return Math.abs(currentProcess.finishedDays) + '天'
      } else {
        return currentProcess.surplusDays <= currentProcess.nodeDays ? currentProcess.surplusDays + '天' : '-'
      }
    }
  }
}
</script>
